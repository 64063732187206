import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => (
  <div className="container mx-auto px-4 py-8">
    <Helmet>
      <title>Privacy Policy - kiteCOMPASS</title>
      <meta name="description" content="Privacy Policy for kiteCOMPASS" />
    </Helmet>
    <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
    <p className="mb-4">Effective Date: October 7, 2024</p>
    <p className="mb-4">kiteCOMPASS (https://www.kitecompass.app) values your privacy. This policy explains how we collect, use, and protect your information.</p>

    <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
    <p className="mb-4">
      • Personal Data: We collect your email address when you sign up for our newsletter or alerts.<br />
      • Non-Personal Data: We collect non-personal data such as web cookies to track site usage and improve our services.
    </p>

    <h2 className="text-2xl font-semibold mb-4">2. Use of Information</h2>
    <p className="mb-4">
      • Personal Data: Your email address is used to send you kitesurfing spot alerts and newsletters.<br />
      • Non-Personal Data: Cookies are used to analyze website traffic, enhance user experience, and for marketing purposes.
    </p>

    <h2 className="text-2xl font-semibold mb-4">3. Sharing of Data</h2>
    <p className="mb-4">We do not sell, trade, or share your personal data with third parties, except as necessary to provide services or comply with legal obligations.</p>

    <h2 className="text-2xl font-semibold mb-4">4. Analytics</h2>
    <p className="mb-4">We use PostHog, an open-source analytics platform, to help us understand how our service is used. PostHog collects information such as your IP address, browser type, operating system, referring webpage, pages visited, and time spent on pages. This data is used to improve our service and is not shared with other third parties for marketing purposes.</p>

    <h2 className="text-2xl font-semibold mb-4">5. Cookies</h2>
    <p className="mb-4">We use cookies to track website usage and improve your experience. You can disable cookies in your browser settings, but doing so may limit your access to certain features.</p>

    <h2 className="text-2xl font-semibold mb-4">6. Security</h2>
    <p className="mb-4">We take reasonable steps to protect your personal data from unauthorized access, disclosure, or misuse. However, no method of transmission over the internet is 100% secure.</p>

    <h2 className="text-2xl font-semibold mb-4">7. User Rights</h2>
    <p className="mb-4">You may request to access, modify, or delete your personal data at any time by contacting us at [Your Contact Email].</p>

    <h2 className="text-2xl font-semibold mb-4">8. Updates to Privacy Policy</h2>
    <p className="mb-4">We may update this policy from time to time. You will be notified of any changes via email.</p>

    <h2 className="text-2xl font-semibold mb-4">9. Governing Law</h2>
    <p className="mb-4">This Privacy Policy is governed by the laws of [Your Country/State].</p>

    <h2 className="text-2xl font-semibold mb-4">10. Contact Information</h2>
    <p className="mb-4">For questions about this policy, please contact us at [Your Contact Email].</p>
  </div>
);

export default PrivacyPolicy;