import React, { useState, useEffect, useCallback } from 'react';
import SpotCard from './SpotCard';
import { searchSpots, searchSpotsHistorical } from '../services/api';
import {
  FaWind,
  FaSun,
  FaMapMarkerAlt,
  FaWater,
  FaGlobe,
  FaMap,
} from 'react-icons/fa';
import { motion } from 'framer-motion';

// Define loadingSteps outside the component to prevent re-creation on each render
const loadingSteps = [
  { icon: FaMap, text: 'Plotting the best routes...' },
  { icon: FaWind, text: 'Measuring wind conditions for you...' },
  { icon: FaSun, text: 'Evaluating sun exposure...' },
  { icon: FaWater, text: 'Calculating perfect wave heights...' },
  { icon: FaGlobe, text: 'Scanning the globe for kite spots...' },
  { icon: FaMapMarkerAlt, text: 'Finalizing your kitesurfing adventure...' },
];

function SpotList({ searchCriteria, modifySearch }) {
  const [allSpots, setAllSpots] = useState([]);
  const [displayedSpots, setDisplayedSpots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingStep, setLoadingStep] = useState(0);
  const [page, setPage] = useState(0);
  const spotsPerPage = 20;

  const fetchSpots = useCallback(async () => {
    setLoading(true);
    setError(null);
    setLoadingProgress(0);
    setLoadingStep(0);

    try {
      const totalSteps = loadingSteps.length;
      const progressIncrement = 100 / totalSteps;
      const intervalDuration = 800;

      const intervalId = setInterval(() => {
        setLoadingProgress((prev) => {
          const newProgress = prev + progressIncrement;
          return newProgress >= 100 ? 100 : newProgress;
        });
        setLoadingStep((prev) => (prev + 1) % totalSteps);
      }, intervalDuration);

      let response;
      const todayDate = new Date().toISOString().split('T')[0];
      if (searchCriteria.end_date <= todayDate) {
        response = await searchSpotsHistorical(searchCriteria);
      } else {
        response = await searchSpots(searchCriteria);
      }

      clearInterval(intervalId);
      setLoadingProgress(100);

      setAllSpots(response.spots);
      setDisplayedSpots(response.spots.slice(0, spotsPerPage));
      setPage(1);
    } catch (error) {
      console.error('Error fetching spots:', error);
      if (error.status === 404) {
        setError('No spots found matching your criteria');
      } else {
        setError('Failed to fetch spots. Please try again.');
      }
    }

    setLoading(false);
  }, [searchCriteria]);

  useEffect(() => {
    fetchSpots();
  }, [searchCriteria, fetchSpots]);

  const loadMoreSpots = useCallback(() => {
    const nextPage = page + 1;
    const newSpots = allSpots.slice(0, nextPage * spotsPerPage);
    setDisplayedSpots(newSpots);
    setPage(nextPage);
  }, [page, allSpots]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        if (displayedSpots.length < allSpots.length) {
          loadMoreSpots();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [displayedSpots, allSpots, loadMoreSpots]);

  if (loading) {
    return (
      <div className="w-full flex flex-col items-center justify-center">
        {error && (
          <div className="text-red-600 text-lg mb-4">
            {error}
          </div>
        )}
        <motion.div
          className="w-full max-w-md px-4 mt-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className="flex items-center mb-4">
            {React.createElement(loadingSteps[loadingStep].icon, {
              className: 'text-4xl text-primary mr-4',
            })}
            <p className="text-lg font-semibold text-primary">
              {loadingSteps[loadingStep].text}
            </p>
          </div>
          <div className="w-full bg-gray-300 rounded-full h-4 relative overflow-hidden">
            <motion.div
              className="bg-brightSun h-4 absolute top-0 left-0"
              initial={{ width: '0%' }}
              animate={{ width: `${loadingProgress}%` }}
              transition={{ duration: 2.5, ease: 'easeInOut' }}
            >
              
            </motion.div>
          </div>
        </motion.div>
      </div>
    );
  }

  return (
    <section className="py-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold">Kite Spots Matching Your Search</h2>
        <button
          onClick={modifySearch}
          className="bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark"
        >
          Modify Search
        </button>
      </div>
      {displayedSpots.length === 0 ? (
        <p className="text-center text-xl">No spots found matching your criteria.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {displayedSpots.map((spot) => (
            <SpotCard
              key={spot.id}
              spot={spot}
              travelDates={{
                startDate: searchCriteria.start_date,
                endDate: searchCriteria.end_date,
              }}
              userPreferences={{
                minWindSpeed: searchCriteria.min_wind_speed,
                maxWindSpeed: searchCriteria.max_wind_speed,
                minTemperature: searchCriteria.min_temperature,
                maxTemperature: searchCriteria.max_temperature,
                timeRangeStart: searchCriteria.time_range_start,
                timeRangeEnd: searchCriteria.time_range_end,
                minDuration: searchCriteria.min_duration,
              }}
              isHistorical={
                spot.weather_data &&
                spot.weather_data.length > 0 &&
                spot.weather_data[0].is_forecast === false
              }
            />
          ))}
        </div>
      )}
      {displayedSpots.length < allSpots.length && (
        <div className="flex justify-center items-center mt-8">
          <button onClick={loadMoreSpots} className="bg-primary text-white px-4 py-2 rounded">
            Load More
          </button>
        </div>
      )}
    </section>
  );
}

export default SpotList;