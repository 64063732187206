import React, { useState } from "react";
import {
  FaWind,
  FaThermometerHalf,
  FaCompass,
  FaCalendarAlt,
  FaClock,
  FaCheckCircle,
} from "react-icons/fa";
import {
  WiDaySunny,
  WiDayCloudy,
  WiCloudy,
  WiFog,
  WiSprinkle,
  WiRain,
  WiSnow,
  WiSnowflakeCold,
  WiRainMix,
  WiStormShowers,
  WiThunderstorm,
  WiHail,
  WiDaySunnyOvercast,
} from "react-icons/wi";
import { motion } from "framer-motion";
import { degreesToCardinal } from "../utils/weatherUtils";

const weatherIcons = {
  0: <WiDaySunny className="text-yellow-500 text-center text-3xl" title="Clear sky" />,
  1: <WiDaySunnyOvercast className="text-gray-400 text-center text-3xl" title="Mainly clear" />,
  2: <WiDayCloudy className="text-gray-500 text-center text-3xl" title="Partly cloudy" />,
  3: <WiCloudy className="text-gray-600 text-center text-3xl" title="Overcast" />,
  45: <WiFog className="text-gray-400 text-center text-3xl" title="Fog" />,
  48: <WiFog className="text-gray-500 text-center text-3xl" title="Depositing rime fog" />,
  51: <WiSprinkle className="text-blue-300 text-center text-3xl" title="Light drizzle" />,
  53: <WiSprinkle className="text-blue-400 text-center text-3xl" title="Moderate drizzle" />,
  55: <WiSprinkle className="text-blue-500 text-center text-3xl" title="Dense drizzle" />,
  56: <WiSnowflakeCold className="text-blue-300 text-center text-3xl" title="Light freezing drizzle" />,
  57: <WiSnowflakeCold className="text-blue-500 text-center text-3xl" title="Dense freezing drizzle" />,
  61: <WiRain className="text-blue-300 text-center text-3xl" title="Slight rain" />,
  63: <WiRain className="text-blue-500 text-center text-3xl" title="Moderate rain" />,
  65: <WiRain className="text-blue-700 text-center text-3xl" title="Heavy rain" />,
  66: <WiRainMix className="text-blue-400 text-center text-3xl" title="Light freezing rain" />,
  67: <WiRainMix className="text-blue-600 text-center text-3xl" title="Heavy freezing rain" />,
  71: <WiSnow className="text-blue-100 text-center text-3xl" title="Slight snow fall" />,
  73: <WiSnow className="text-blue-300 text-center text-3xl" title="Moderate snow fall" />,
  75: <WiSnow className="text-blue-500 text-center text-3xl" title="Heavy snow fall" />,
  77: <WiSnowflakeCold className="text-blue-200 text-center text-3xl" title="Snow grains" />,
  80: <WiRain className="text-blue-300 text-center text-3xl" title="Slight rain showers" />,
  81: <WiRain className="text-blue-500 text-center text-3xl" title="Moderate rain showers" />,
  82: <WiRain className="text-blue-700 text-center text-3xl" title="Violent rain showers" />,
  85: <WiSnow className="text-blue-300 text-center text-3xl" title="Slight snow showers" />,
  86: <WiSnow className="text-blue-500 text-center text-3xl" title="Heavy snow showers" />,
  95: <WiStormShowers className="text-yellow-500 text-center text-3xl" title="Thunderstorm" />,
  96: <WiThunderstorm className="text-yellow-600 text-center text-3xl" title="Thunderstorm with slight hail" />,
  99: <WiHail className="text-yellow-700 text-center text-3xl" title="Thunderstorm with heavy hail" />,
  default: <WiDaySunny className="text-yellow-500 text-center text-3xl" title="Clear sky" />,
};

const windSpeedColors = [
  { threshold: 0, color: [98, 113, 183] },
  { threshold: 5, color: [57, 97, 159] },
  { threshold: 10, color: [74, 148, 169] },
  { threshold: 15, color: [77, 141, 123] },
  { threshold: 20, color: [83, 165, 83] },
  { threshold: 25, color: [53, 159, 53] },
  { threshold: 30, color: [167, 157, 81] },
  { threshold: 35, color: [159, 127, 58] },
  { threshold: 40, color: [161, 108, 92] },
  { threshold: 45, color: [129, 58, 78] },
  { threshold: 50, color: [175, 80, 136] },
  { threshold: 55, color: [117, 74, 147] },
  { threshold: 60, color: [109, 97, 163] },
  { threshold: 65, color: [68, 105, 141] },
  { threshold: 70, color: [92, 144, 152] },
  { threshold: 75, color: [125, 68, 165] },
  { threshold: 80, color: [231, 215, 215] },
  { threshold: 85, color: [219, 212, 135] },
  { threshold: 90, color: [205, 202, 112] },
  { threshold: 95, color: [128, 128, 128] },
];

const getWindSpeedColor = (speed) => {
  if (speed === null || speed === undefined) return "#ccc";
  for (let i = 0; i < windSpeedColors.length - 1; i++) {
    if (
      speed >= windSpeedColors[i].threshold &&
      speed < windSpeedColors[i + 1].threshold
    ) {
      const ratio =
        (speed - windSpeedColors[i].threshold) /
        (windSpeedColors[i + 1].threshold - windSpeedColors[i].threshold);
      return interpolateColor(
        windSpeedColors[i].color,
        windSpeedColors[i + 1].color,
        ratio,
      );
    }
  }
  return `rgb(${windSpeedColors[windSpeedColors.length - 1].color.join(",")})`;
};

const interpolateColor = (color1, color2, ratio) => {
  const r = Math.round(color1[0] + (color2[0] - color1[0]) * ratio);
  const g = Math.round(color1[1] + (color2[1] - color1[1]) * ratio);
  const b = Math.round(color1[2] + (color2[2] - color1[2]) * ratio);
  return `rgb(${r},${g},${b})`;
};

const SpotCard = ({ spot, travelDates, userPreferences, isHistorical }) => {
  const { startDate, endDate } = travelDates;
  const {
    minWindSpeed,
    maxWindSpeed,
    minTemperature,
    maxTemperature,
    timeRangeStart,
    timeRangeEnd,
  } = userPreferences;

  const [isExpanded, setIsExpanded] = useState(false);

  if (!spot) {
    return null;
  }

  // Process weather data based on whether it's historical or forecast
  const processWeatherData = () => {
    if (!spot.weather_data || !Array.isArray(spot.weather_data)) {
      return [];
    }

    return spot.weather_data
      .map((day) => {
        if (isHistorical) {
          // Historical data processing remains unchanged
          return {
            date: day.date,
            suitabilityProbability: day.suitability_probability,
            avgWindSpeed: day.avg_wind_speed,
            avgTemperature: day.avg_temperature,
            avgPrecipitation: day.avg_precipitation,
            mostCommonWeatherCode: day.most_common_weather_code,
            mostLikelyPeriods: day.most_likely_periods,
          };
        } else {
          // For forecast data, calculate averages from hourly data
          const hourlyData = day.hourly_metrics || {};
          const windSpeeds = [];
          const temperatures = [];
          const precipitations = [];
          const windDirections = [];

          // We will no longer collect weather codes from hourly data
          // const weatherCodes = [];  // Remove this line

          Object.values(hourlyData).forEach((metrics) => {
            metrics.forEach((metric) => {
              if (
                metric.metric_name === "wind_speed" &&
                typeof metric.value === "number"
              ) {
                windSpeeds.push(metric.value);
              } else if (
                metric.metric_name === "temperature" &&
                typeof metric.value === "number"
              ) {
                temperatures.push(metric.value);
              } else if (
                metric.metric_name === "precipitation" &&
                typeof metric.value === "number"
              ) {
                precipitations.push(metric.value);
              } else if (
                metric.metric_name === "wind_direction" &&
                typeof metric.value === "number"
              ) {
                windDirections.push(metric.value);
              }
              // Remove weather code collection from hourly data
              // else if (metric.metric_name === 'weather_code' && typeof metric.value === 'number') {
              //   weatherCodes.push(metric.value);
              // }
            });
          });

          return {
            date: day.date,
            windSpeed: windSpeeds.length
              ? windSpeeds.reduce((sum, speed) => sum + speed, 0) /
                windSpeeds.length
              : null,
            temperature: temperatures.length
              ? temperatures.reduce((sum, temp) => sum + temp, 0) /
                temperatures.length
              : null,
            precipitation: precipitations.length
              ? precipitations.reduce((sum, precip) => sum + precip, 0) /
                precipitations.length
              : null,
            windDirection: windDirections.length
              ? windDirections.reduce((sum, dir) => sum + dir, 0) /
                windDirections.length
              : null,
            // Use daily_weather_code instead of calculating from hourly data
            weatherCode: day.daily_weather_code || null,
            meetsCriteria: day.meetsCriteria,
            is_forecast: day.is_forecast,
            hourlyMetrics: day.hourly_metrics,
          };
        }
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const weatherData = processWeatherData();

  const avgWindSpeed = spot.avg_wind_speed || 0;
  const avgTemperature = spot.avg_temperature || 0;
  const avgWindDirection =
    !isHistorical && weatherData.length > 0
      ? weatherData.reduce((sum, day) => sum + (day.windDirection || 0), 0) /
        weatherData.length
      : null;

  const suitabilityPercentage = spot.suitability_percentage || 0;

  // Function to determine suitable time periods for forecast data
  const getSuitableTimePeriodsForForecast = (hourlyMetrics) => {
    const suitableHours = [];
    for (let hour = timeRangeStart; hour <= timeRangeEnd; hour++) {
      const metrics = hourlyMetrics[hour] || [];
      const windSpeed = metrics.find(
        (m) => m.metric_name === "wind_speed",
      )?.value;
      const temperature = metrics.find(
        (m) => m.metric_name === "temperature",
      )?.value;

      if (
        windSpeed !== undefined &&
        windSpeed >= minWindSpeed &&
        windSpeed <= maxWindSpeed &&
        temperature !== undefined &&
        temperature >= minTemperature &&
        temperature <= maxTemperature
      ) {
        suitableHours.push(hour);
      }
    }
    // Group consecutive hours
    const periods = [];
    let startHour = null;
    let prevHour = null;
    suitableHours.forEach((hour) => {
      if (startHour === null) {
        startHour = hour;
      } else if (hour !== prevHour + 1) {
        periods.push([startHour, prevHour]);
        startHour = hour;
      }
      prevHour = hour;
    });
    if (startHour !== null) {
      periods.push([startHour, prevHour]);
    }
    return periods;
  };

  // Render Suitable Time Periods
  const renderDetailedConditions = () => {
    const detailedConditions = weatherData
      .filter((day) => {
        if (isHistorical) {
          return day.suitabilityProbability > 0;
        } else {
          return day.meetsCriteria;
        }
      })
      .map((day) => {
        let formattedPeriods;
        if (isHistorical) {
          formattedPeriods = day.mostLikelyPeriods
            .map(([startHour, endHour]) => `${startHour}:00-${endHour + 1}:00`)
            .join(", ");
        } else {
          const periods = getSuitableTimePeriodsForForecast(
            day.hourlyMetrics || {},
          );
          formattedPeriods =
            periods.length > 0
              ? periods
                  .map(([start, end]) => `${start}:00-${end + 1}:00`)
                  .join(", ")
              : "No specific period";
        }

        return (
          <div key={day.date} className="mb-2">
            <FaCheckCircle className="inline-block mr-2 text-green-500" />
            <span className="font-semibold">
              {new Date(day.date).toLocaleDateString()}:{" "}
            </span>
            {formattedPeriods}
          </div>
        );
      })
      .filter(Boolean);

    if (detailedConditions.length === 0) {
      return null;
    }

    const displayedConditions = isExpanded
      ? detailedConditions
      : detailedConditions.slice(0, 3);

    return (
      <div className="mt-4 border-t pt-4">
        <h4 className="text-sm font-semibold mb-2">Suitable Time Periods:</h4>
        {displayedConditions}
        {detailedConditions.length > 3 && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-blue-500 hover:underline mt-2"
          >
            {isExpanded
              ? "Show Less"
              : `Show All (${detailedConditions.length})`}
          </button>
        )}
      </div>
    );
  };

  return (
    <motion.div
      className="bg-white shadow rounded-lg overflow-hidden"
      whileHover={{ scale: 1.02 }}
    >
      <div className="p-4">
        <h3 className="text-xl font-bold text-primary mb-2">{spot.name}</h3>
        <p className="text-gray-600 mb-4">{spot.country}</p>
        <div className="flex justify-around mb-4">
          <div className="text-center flex flex-col items-center">
            <div className="w-12 h-12 flex items-center justify-center">
              <FaWind className="text-primary text-2xl" />
            </div>
            <p className="text-lg font-semibold">
              {avgWindSpeed ? avgWindSpeed.toFixed(1) : "-"} kn
            </p>
            <p className="text-sm text-gray-500">Avg Wind</p>
          </div>
          <div className="text-center flex flex-col items-center">
            <div className="w-12 h-12 flex items-center justify-center">
              <FaThermometerHalf className="text-secondary text-2xl" />
            </div>
            <p className="text-lg font-semibold">
              {avgTemperature ? avgTemperature.toFixed(1) : "-"}°C
            </p>
            <p className="text-sm text-gray-500">Avg Temp</p>
          </div>
          <div className="text-center flex flex-col items-center">
            <div className="w-12 h-12 flex items-center justify-center">
              <FaCompass className="text-accent text-2xl" />
            </div>
            <p className="text-lg font-semibold">
              {isHistorical
                ? spot.avg_wind_direction
                  ? degreesToCardinal(Math.round(spot.avg_wind_direction))
                  : "-"
                : isNaN(avgWindDirection)
                  ? "-"
                  : degreesToCardinal(Math.round(avgWindDirection))}
            </p>
            <p className="text-sm text-gray-500">Avg Direction</p>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex items-center justify-between">
            <span className="text-sm font-semibold">Suitability:</span>
            <span className="text-sm font-semibold">
              {suitabilityPercentage.toFixed(0)}%
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className="bg-blue-600 h-2.5 rounded-full"
              style={{ width: `${suitabilityPercentage}%` }}
            ></div>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            {
              weatherData.filter((day) =>
                isHistorical
                  ? day.suitabilityProbability > 0
                  : day.meetsCriteria,
              ).length
            }{" "}
            out of {weatherData.length} days are suitable
          </p>
        </div>

        <div className="mt-4">
          <h4 className="text-sm font-semibold mb-2">
            {isHistorical ? "Historical Averages" : "Forecast"}
          </h4>
          <div className="overflow-x-auto">
            <table className="w-full text-xs">
              <thead>
                <tr>
                  <th className="px-1 py-2 bg-gray-100"></th>
                  {weatherData.map((data, index) => (
                    <th
                      key={index}
                      className={`px-1 py-2 text-gray-600 font-normal ${
                        (
                          isHistorical
                            ? data.suitabilityProbability > 50
                            : data.meetsCriteria
                        )
                          ? "bg-blue-50"
                          : "bg-gray-100"
                      }`}
                    >
                      {new Date(data.date).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                      })}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* Suitability Row for Historical Data */}
                {isHistorical && (
                  <tr>
                    <td className="px-1 py-2 font-semibold bg-gray-50 text-gray-500 text-xs">
                      Suitability
                    </td>
                    {weatherData.map((data, index) => (
                      <td
                        key={index}
                        className={`px-1 py-2 text-center ${
                          data.suitabilityProbability > 50
                            ? "bg-blue-50"
                            : "bg-white"
                        }`}
                      >
                        <span>{data.suitabilityProbability.toFixed(0)}%</span>
                      </td>
                    ))}
                  </tr>
                )}
                {/* Weather Icons */}
                <tr>
                  <td className="px-1 py-2 font-semibold bg-gray-50 text-gray-500 text-xs">
                    Weather
                  </td>
                  {weatherData.map((data, index) => (
                    <td
                      key={index}
                      className={`px-1 py-2 text-center ${
                        (isHistorical
                          ? data.suitabilityProbability > 50
                          : data.meetsCriteria)
                          ? "bg-blue-50"
                          : "bg-white"
                      }`}
                    >
                      <div className="flex justify-center items-center h-8">
                        {(() => {
                          const code = isHistorical
                            ? data.mostCommonWeatherCode
                            : data.weatherCode;
                          return code !== null && code !== undefined
                            ? weatherIcons[code] || weatherIcons.default
                            : "-";
                        })()}
                      </div>
                    </td>
                  ))}
                </tr>

                {/* Wind Speed Row */}
                <tr>
                  <td className="px-1 py-2 font-semibold bg-gray-50 text-gray-500 text-xs">
                    Wind <span className="font-normal text-gray-400">(kn)</span>
                  </td>
                  {weatherData.map((data, index) => {
                    const windSpeed = isHistorical
                      ? data.avgWindSpeed
                      : data.windSpeed;
                    return (
                      <td
                        key={index}
                        className={`px-1 py-2 text-center ${
                          (
                            isHistorical
                              ? data.suitabilityProbability > 50
                              : data.meetsCriteria
                          )
                            ? "bg-blue-50"
                            : "bg-white"
                        }`}
                      >
                        <span
                          className="px-2 py-1 rounded"
                          style={{
                            backgroundColor: getWindSpeedColor(windSpeed),
                            color: "white",
                          }}
                        >
                          {windSpeed !== null && windSpeed !== undefined
                            ? Math.round(windSpeed)
                            : "-"}
                        </span>
                      </td>
                    );
                  })}
                </tr>
                {/* Temperature Row */}
                <tr>
                  <td className="px-1 py-2 font-semibold bg-gray-50 text-gray-500 text-xs">
                    Temp <span className="font-normal text-gray-400">(°C)</span>
                  </td>
                  {weatherData.map((data, index) => {
                    const temperature = isHistorical
                      ? data.avgTemperature
                      : data.temperature;
                    return (
                      <td
                        key={index}
                        className={`px-1 py-2 text-center ${
                          (
                            isHistorical
                              ? data.suitabilityProbability > 50
                              : data.meetsCriteria
                          )
                            ? "bg-blue-50"
                            : "bg-white"
                        }`}
                      >
                        <span>
                          {temperature !== null && temperature !== undefined
                            ? Math.round(temperature)
                            : "-"}
                        </span>
                      </td>
                    );
                  })}
                </tr>
                {/* Precipitation Row */}
                <tr>
                  <td className="px-1 py-2 font-semibold bg-gray-50 text-gray-500 text-xs">
                    Rain <span className="font-normal text-gray-400">(mm)</span>
                  </td>
                  {weatherData.map((data, index) => {
                    const precipitation = isHistorical
                      ? data.avgPrecipitation
                      : data.precipitation;
                    return (
                      <td
                        key={index}
                        className={`px-1 py-2 text-center text-blue-600 text-xs ${
                          (
                            isHistorical
                              ? data.suitabilityProbability > 50
                              : data.meetsCriteria
                          )
                            ? "bg-blue-50"
                            : "bg-white"
                        }`}
                      >
                        {precipitation !== null && precipitation !== undefined
                          ? precipitation.toFixed(1)
                          : "-"}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Footer Information */}
        <div className="mt-4 text-xs text-gray-500">
          <div className="flex items-center mb-1">
            <FaCalendarAlt className="mr-2" />
            <span>
              {isHistorical ? "Historical averages" : "Forecast data"} for{" "}
              {startDate} to {endDate}
            </span>
          </div>
          <div className="flex items-center">
            <FaClock className="mr-2" />
            <span>
              Time range: {timeRangeStart}:00 - {timeRangeEnd}:00
            </span>
          </div>
        </div>

        {/* Suitable Time Periods */}
        {renderDetailedConditions()}
      </div>
    </motion.div>
  );
};

export default SpotCard;