// src/components/AnnouncementBanner.js
import React from 'react';

const AnnouncementBanner = () => (
  <div className="sticky top-0 z-50 bg-primary text-white text-xs sm:text-sm shadow-lg">
    <div className="flex flex-wrap justify-center items-center px-4 py-2">
      <p className="mb-1 sm:mb-0 sm:mr-2">
        🏄‍♂️ kiteCOMPASS is in beta. Help me improve!
      </p>
      <a
        href="https://insigh.to/b/kitecompass"
        target="_blank"
        rel="noopener noreferrer"
        className="underline hover:text-secondary"
      >
        Give Feedback
      </a>
    </div>
  </div>
);

export default AnnouncementBanner;