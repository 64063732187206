// src/components/SearchForm.js

import React, { useState, useEffect } from 'react';
import {
  FaSearch,
  FaCalendarAlt,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getCountries, getContinents } from '../services/api';
import Select from 'react-select';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

function SearchForm({ onSearch }) {
  const [searchParams, setSearchParams] = useState({
    mode: 'month',
    date_range: [
      new Date(),
      new Date(new Date().setDate(new Date().getDate() + 7)),
    ],
    month: new Date(),
    wind_speed: [18, 25], // Adjusted initial values
    temperature: [22, 25], // Adjusted initial values
    time_range: [8, 20],
    min_duration: 2,
    countries: [],
    continents: [],
    showAdvanced: false,
  });

  const [countries, setCountries] = useState([]);
  const [continents, setContinents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countriesData = await getCountries();
        const continentsData = await getContinents();
        setCountries(
          countriesData.map((country) => ({ value: country, label: country }))
        );
        setContinents(
          continentsData.map((continent) => ({
            value: continent,
            label: continent,
          }))
        );
      } catch (error) {
        console.error('Error fetching countries or continents:', error);
      }
    };
    fetchData();
  }, []);

  const handleChange = (name, value) => {
    setSearchParams((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { mode, date_range, month } = searchParams;

    let startDate, endDate;

    if (mode === 'date') {
      startDate = date_range[0];
      endDate = date_range[1] || date_range[0];
    } else {
      const today = new Date();
      const selectedMonth = month;
      const year = selectedMonth.getFullYear();
      const monthIndex = selectedMonth.getMonth();

      // If the selected month is the current month, start from today
      if (
        year === today.getFullYear() &&
        monthIndex === today.getMonth()
      ) {
        startDate = today;
      } else {
        startDate = new Date(year, monthIndex, 1);
      }
      endDate = new Date(year, monthIndex + 1, 0);
    }

    onSearch({
      ...searchParams,
      start_date: startDate.toISOString().split('T')[0],
      end_date: endDate.toISOString().split('T')[0],
      min_wind_speed: searchParams.wind_speed[0],
      max_wind_speed: searchParams.wind_speed[1],
      min_temperature: searchParams.temperature[0],
      max_temperature: searchParams.temperature[1],
      time_range_start: searchParams.time_range[0],
      time_range_end: searchParams.time_range[1],
      countries: searchParams.countries.map((country) => country.value),
      continents: searchParams.continents.map((continent) => continent.value),
    });
  };

  // Formatting functions
  const formatTime = (value) => {
    const hours = Math.floor(value);
    return `${hours.toString().padStart(2, '0')}:00`;
  };

  // Calculate maxDate (1 year from now)
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);

  return (
    <div className="bg-white p-4 mt-10 rounded-lg shadow-lg border-2 border-primary/80">
      <h3 className="text-xl font-bold mb-6 text-gray-700">
        Let's Find Your Perfect Kitesurfing Destination!
      </h3>
      <form onSubmit={handleSubmit}>
        {/* Datepicker Input Field */}
        <div className="mt-6">
          <label htmlFor="month-picker" class="block text-gray-700 mb-2">
            Select Your Travel Dates or Months
          </label>
          <div className="flex space-x-2 mb-2">
            <button
              type="button"
              onClick={() => handleChange('mode', 'date')}
              className={`flex-1 py-2 rounded-md focus:outline-none ${
                searchParams.mode === 'date'
                  ? 'bg-primary text-white hover:bg-primary-dark'
                  : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
              }`}
            >
              Select Dates
            </button>
            <button
              type="button"
              onClick={() => handleChange('mode', 'month')}
              className={`flex-1 py-2 rounded-md focus:outline-none ${
                searchParams.mode === 'month'
                  ? 'bg-primary text-white hover:bg-primary-dark'
                  : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
              }`}
            >
              Select Month
            </button>
          </div>
          <div className="relative">
            <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            {searchParams.mode === 'date' ? (
              <DatePicker
                id="date-picker"
                name="date_range"
                selected={searchParams.date_range[0]}
                onChange={(dates) => handleChange('date_range', dates)}
                startDate={searchParams.date_range[0]}
                endDate={searchParams.date_range[1]}
                selectsRange
                minDate={new Date()}
                maxDate={maxDate}
                popperClassName="datepicker-popper"
                className="w-full pl-10 pr-3 py-2 rounded-lg border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-gray-700"
                placeholderText="Click to select dates"
              />
            ) : (
              <DatePicker
                id="month-picker"
                name="month"
                selected={searchParams.month}
                onChange={(date) => handleChange('month', date)}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                minDate={new Date()}
                maxDate={maxDate}
                popperClassName="datepicker-popper"
                className="w-full pl-10 pr-3 py-2 rounded-lg border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-gray-700"
                placeholderText="Click to select a month"
              />
            )}
          </div>
        </div>

        {/* Wind Speed and Temperature Sliders */}
        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Wind Speed Slider */}
          <div>
  <div className="flex items-center justify-between mb-2">
    <span className="block text-gray-700">Preferred Wind Speed Range (Knots)</span>
    <span className="text-sm text-indigo-700 font-semibold">
      {searchParams.wind_speed[0]} - {searchParams.wind_speed[1]} knots
    </span>
  </div>
  <Slider
    id="knots-range"
    name="wind_speed"
    range
    min={0}
    max={50}
    step={1}
    value={searchParams.wind_speed}
    onChange={(value) => handleChange('wind_speed', value)}
    handleRender={(node, handleProps) => (
      <Tooltip
        overlay={`${handleProps.value} knots`}
        visible={handleProps.dragging}
        placement="top"
        key={handleProps.index}
      >
        {node}
      </Tooltip>
    )}
    trackStyle={[{ backgroundColor: '#4F46E5' }]}
    handleStyle={[
      { borderColor: '#4F46E5', backgroundColor: '#4F46E5' },
      { borderColor: '#4F46E5', backgroundColor: '#4F46E5' },
    ]}
    railStyle={{ backgroundColor: '#E5E7EB' }}
  />
</div>

          {/* Temperature Slider */}
          <div>
  <div className="flex items-center justify-between mb-2">
    <span className="block text-gray-700">Preferred Temperature Range (&deg;C)</span>
    <span className="text-sm text-emerald-500 font-semibold">
      {searchParams.temperature[0]}&deg;C - {searchParams.temperature[1]}&deg;C
    </span>
  </div>
  <Slider
    id="temperature-range"
    name="temperature"
    range
    min={0}
    max={40}
    step={1}
    value={searchParams.temperature}
    onChange={(value) => handleChange('temperature', value)}
    handleRender={(node, handleProps) => (
      <Tooltip
        overlay={`${handleProps.value}°C`}
        visible={handleProps.dragging}
        placement="top"
        key={handleProps.index}
      >
        {node}
      </Tooltip>
    )}
    trackStyle={[{ backgroundColor: '#10B981' }]}
    handleStyle={[
      { borderColor: '#10B981', backgroundColor: '#10B981' },
      { borderColor: '#10B981', backgroundColor: '#10B981' },
    ]}
    railStyle={{ backgroundColor: '#E5E7EB' }}
  />
</div>
        </div>

        {/* Country and Continent Dropdowns */}
        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="countries-select" className="block text-gray-700">
              Select Countries to Explore
            </label>
            <Select
              inputId="countries-select"
              name="countries"
              isMulti
              options={countries}
              value={searchParams.countries}
              onChange={(selected) => handleChange('countries', selected || [])}
              placeholder="Select countries..."
              className="mt-1"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </div>
          <div>
            <label htmlFor="continents-select" className="block text-gray-700">
              Select Continents to Explore
            </label>
            <Select
              inputId="continents-select"
              name="continents"
              isMulti
              options={continents}
              value={searchParams.continents}
              onChange={(selected) => handleChange('continents', selected || [])}
              placeholder="Select continents..."
              className="mt-1"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </div>
        </div>

        {/* Advanced Search Options */}
        <div className="mt-6">
          <button
            type="button"
            onClick={() =>
              handleChange('showAdvanced', !searchParams.showAdvanced)
            }
            className="flex items-center text-primary hover:text-primary-dark focus:outline-none font-semibold"
          >
            <span>Advanced Search Options</span>
            {searchParams.showAdvanced ? (
              <FaChevronUp className="ml-2" />
            ) : (
              <FaChevronDown className="ml-2" />
            )}
          </button>
          {searchParams.showAdvanced && (
            <div className="mt-4 space-y-4">
              {/* Time Range Slider */}
              <div>
                <label htmlFor="time-range" className="block text-gray-700 mb-2">
                  Preferred Time Range (Hours)
                </label>
                <Slider
                  id="time-range"
                  name="time_range"
                  range
                  min={0}
                  max={23}
                  step={1}
                  value={searchParams.time_range}
                  onChange={(value) => handleChange('time_range', value)}
                  handleRender={(node, handleProps) => (
                    <Tooltip
                      overlay={formatTime(handleProps.value)}
                      visible={handleProps.dragging}
                      placement="top"
                      key={handleProps.index}
                    >
                      {node}
                    </Tooltip>
                  )}
                  trackStyle={[{ backgroundColor: '#F59E0B' }]}
                  handleStyle={[
                    { borderColor: '#F59E0B', backgroundColor: '#F59E0B' },
                    { borderColor: '#F59E0B', backgroundColor: '#F59E0B' },
                  ]}
                  railStyle={{ backgroundColor: '#E5E7EB' }}
                />
                <div className="flex justify-between text-sm text-gray-600 mt-2">
                  <span>{formatTime(searchParams.time_range[0])}</span>
                  <span>{formatTime(searchParams.time_range[1])}</span>
                </div>
              </div>

              {/* Minimum Duration Field in Hours */}
              <div>
                <label htmlFor="min-duration" className="block text-gray-700">
                  Minimum Duration of Kitesurfing Session (Hours)
                </label>
                <input
                  id="min-duration"
                  name="min_duration"
                  type="number"
                  min="0"
                  value={searchParams.min_duration}
                  onChange={(e) =>
                    handleChange(
                      'min_duration',
                      parseInt(e.target.value, 10) || 0
                    )
                  }
                  className="mt-1 w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 py-2 px-3 text-gray-700"
                />
              </div>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button
            type="submit"
            className="w-full bg-primary text-white py-3 px-4 rounded-md hover:bg-primary-dark focus:outline-none focus:bg-primary"
          >
            <FaSearch className="inline-block mr-2" />
            Search
          </button>
        </div>
      </form>
    </div>
  );
}

export default SearchForm;