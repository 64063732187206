// HeroSearch.js

import React, { useState } from 'react';
import SearchForm from './SearchForm';
import SpotList from './SpotList';

function HeroSearch() {
  const [searchCriteria, setSearchCriteria] = useState(null);
  const [searching, setSearching] = useState(false);

  const handleSearch = (criteria) => {
    setSearchCriteria(criteria);
    setSearching(true); // Set to true when search starts
  };

  const modifySearch = () => {
    setSearching(false); // Show search form again
  };

  return (
    <section className="py-4 mt-4">
      <div className="container mx-auto px-4">
        <div className="text-left mb-6">
          <h1 className="text-2xl md:text-3xl font-bold text-primary">
            Find the Best Kitesurfing Spots Based on Your Ideal Conditions
          </h1>
          <h2 className="mt-4 text-lg text-gray-700">
            Set your preferred wind speed, temperature, and travel dates to discover perfect kitesurfing destinations worldwide.
          </h2>
        </div>
        
        {!searching && (
          <SearchForm onSearch={handleSearch} />
        )}
        
        {searching && searchCriteria && (
          <SpotList searchCriteria={searchCriteria} modifySearch={modifySearch} />
        )}
      </div>
    </section>
  );
}

export default HeroSearch;