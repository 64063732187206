import React from 'react';
import { Helmet } from 'react-helmet';

const TermsOfService = () => (
  <div className="container mx-auto px-4 py-8">
    <Helmet>
      <title>Terms of Service - kiteCOMPASS</title>
      <meta name="description" content="Terms of Service for kiteCOMPASS" />
    </Helmet>
    <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
    <p className="mb-4">Effective Date: October 7, 2024</p>
    <p className="mb-4">Welcome to kiteCOMPASS (https://www.kitecompass.app). By using our website and services, you agree to comply with the following terms.</p>

    <h2 className="text-2xl font-semibold mb-4">1. Overview</h2>
    <p className="mb-4">kiteCOMPASS is a kitesurfing spot finder designed to help users discover ideal locations based on weather conditions and personal preferences.</p>

    <h2 className="text-2xl font-semibold mb-4">2. Use of Service</h2>
    <p className="mb-4">You agree to use kiteCOMPASS only for purposes that are legal, proper and in accordance with these Terms and any applicable laws or regulations.</p>

    <h2 className="text-2xl font-semibold mb-4">3. User Data</h2>
    <p className="mb-4">We collect and use your data as described in our Privacy Policy. By using kiteCOMPASS, you agree to our data practices as described in our Privacy Policy.</p>

    <h2 className="text-2xl font-semibold mb-4">4. Disclaimer of Warranties</h2>
    <p className="mb-4">kiteCOMPASS is provided "as is" without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose.</p>

    <h2 className="text-2xl font-semibold mb-4">5. Limitation of Liability</h2>
    <p className="mb-4">kiteCOMPASS shall not be liable for any damages whatsoever, including but not limited to any direct, indirect, special, consequential, punitive or incidental damages, or damages for loss of use, profits, data or other intangibles, or the cost of procurement of substitute goods or services arising out of or related to the use, inability to use, unauthorized use, performance or non-performance of the service.</p>

    <h2 className="text-2xl font-semibold mb-4">6. Changes to Terms</h2>
    <p className="mb-4">We reserve the right to modify these Terms at any time. We will always post the most current version on our site. By continuing to use kiteCOMPASS after changes become effective, you agree to be bound by the revised terms.</p>

    <h2 className="text-2xl font-semibold mb-4">7. Governing Law</h2>
    <p className="mb-4">These Terms & Services are governed by the laws of Germany.</p>

    <h2 className="text-2xl font-semibold mb-4">8. Contact</h2>
    <p className="mb-4">For questions or concerns, please contact us at max@kitecompass.app.</p>
  </div>
);

export default TermsOfService;