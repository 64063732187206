import axios from 'axios';
import qs from 'qs';

const API_URL = process.env.REACT_APP_API_URL || 'https://kitecompass.app/api';

export const getSpots = async (skip = 0, limit = 10) => {
  try {
    const response = await axios.get(`${API_URL}/spots/`, {
      params: {
        skip,
        limit
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching spots:', error);
    throw error;
  }
};

export const searchSpots = async (searchCriteria) => {
  try {
    const params = {
      min_wind_speed: searchCriteria.min_wind_speed,
      max_wind_speed: searchCriteria.max_wind_speed,
      min_temperature: searchCriteria.min_temperature,
      max_temperature: searchCriteria.max_temperature,
      start_date: searchCriteria.start_date,
      end_date: searchCriteria.end_date,
      time_range_start: searchCriteria.time_range_start,
      time_range_end: searchCriteria.time_range_end,
      min_duration: searchCriteria.min_duration,
      countries: searchCriteria.countries,
      continents: searchCriteria.continents,
    };

    const response = await axios.get(`${API_URL}/search/`, {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    });

    return response.data;
  } catch (error) {
    console.error('Error searching spots:', error);
    if (error.response && error.response.status === 404) {
      const customError = new Error('No spots found matching the criteria');
      customError.status = 404;
      throw customError;
    }
    throw error;
  }
};

export const searchSpotsHistorical = async (searchCriteria) => {
  try {
    const params = {
      min_wind_speed: searchCriteria.min_wind_speed,
      max_wind_speed: searchCriteria.max_wind_speed,
      min_temperature: searchCriteria.min_temperature,
      max_temperature: searchCriteria.max_temperature,
      start_date: searchCriteria.start_date,
      end_date: searchCriteria.end_date,
      time_range_start: searchCriteria.time_range_start,
      time_range_end: searchCriteria.time_range_end,
      min_duration: searchCriteria.min_duration,
      countries: searchCriteria.countries,
      continents: searchCriteria.continents,
    };

    const response = await axios.get(`${API_URL}/search/historical/`, {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    });

    return response.data;
  } catch (error) {
    console.error('Error searching historical spots:', error);
    if (error.response && error.response.status === 404) {
      const customError = new Error('No historical spots found matching the criteria');
      customError.status = 404;
      throw customError;
    }
    throw error;
  }
};

export const getCountries = async () => {
  try {
    const response = await axios.get(`${API_URL}/countries/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching countries:', error);
    throw error;
  }
};

export const getContinents = async () => {
  try {
    const response = await axios.get(`${API_URL}/continents/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching continents:', error);
    throw error;
  }
};